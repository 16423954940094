<template>
  <div class="approvalDetailsPageBox page-info-content">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="详情" name="first">
        <Details-page :column="2" :details-title="''" :detail-obj="detailsObj" :details-item-arr="detailsItemArr" />
      </el-tab-pane>
      <el-tab-pane label="流程监控" name="second">
        <div class="flowImgBox">
          <img v-if="imgSrc" class="flowImg" :src="imgSrc" alt="流程监控">
          <el-empty v-else description="暂无图片" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage'
import axios from 'axios'
import { baseURL } from '@/systemConfiguration/index'
import { auditInfoQueryBydetail } from '@/api/generationMining'
export default {
  components: { DetailsPage },
  data() {
    return {
      activeTab: 'first',
      detailsObj: {},
      imgSrc: '',
      detailsItemArr: [
        { label: '审批编号', value: 'auditNo' },
        { label: '审批状态', value: 'auditStatus', child: [] },
        // { label: '审批类型', value: 'auditType', child: [{ val: '用印审批', id: 1 }, { val: '付款审批', id: 2 }] },
        { label: '业务金额', money: 'busiAmount' },
        { label: '业务类型', value: 'busiType', child: [{ val: '授信', id: 1 }, { val: '合同', id: 2 }] },
        { label: '发起时间', value: 'createTime' },
        { label: '经办人', value: 'operName' },
        { label: '联系电话', value: 'linkPhone' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      auditInfoQueryBydetail({ id: this.$route.query.id }, res => {
        this.detailsItemArr[1].child = res.data.busiType === 1 ? [...this.$store.getters.getDictionaryItem('OA_ESIGN_STATUS')] : [...this.$store.getters.getDictionaryItem('OA_PAY_STATUS')]
        this.detailsVisible = true
        this.detailsObj = { ...res.data }
      })
    }
    if (this.$route.query.activiteId && this.$route.query.currentNodeId) {
      axios({
        url: `${baseURL}/task/getDiagram?activiteId=${this.$route.query.activiteId}&currentNodeId=${this.$route.query.currentNodeId}`,
        method: 'GET',
        'headers': {
          'token': localStorage.getItem('logintoken')
        },
        responseType: 'blob'
      }).then(res => {
        // this.imgSrc = res.data
        const blob = new Blob([res.data], {
          type: 'image/png'
        })
        var reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          this.imgSrc = reader.result
          this.$forceUpdate()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.approvalDetailsPageBox {
  .flowImgBox {
    text-align: center;

    .flowImg {
      margin: 56px;
    }
  }

}
</style>
